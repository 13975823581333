import React from 'react';
import {
	Box,
	Button,
	LinearProgress,
    Slider,
} from '@mui/material';
import { Formik, Form } from 'formik';
import Layout from '../layout';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { Timestamp, } from "firebase/firestore";

const formContainer = {
	width: '100%',
	marginTop: '32px',
};

const BeautyUserForm = (props) => {
    const [sliderMarks, setSliderMarks] = React.useState([]);

    const calculateSliderMarks = (max, increment) => {
        const numberOfMarks = parseFloat(max) / parseFloat(increment);
        const marks = [];
        if (numberOfMarks > 50) {
            return setSliderMarks(marks);
        }
        for (let i = 0; i < numberOfMarks; i+=increment) {
            marks.push({
                value: Math.round((i + Number.EPSILON) * 100) / 100,
                label: `${Math.round((i + Number.EPSILON) * 100) / 100}`
            });
        }
        return setSliderMarks(marks);
    }

    React.useEffect(() => {
        calculateSliderMarks(props.value, props.increment);
    }, [props.value, props.increment])

	return(
        <Layout>
            <Formik
                initialValues = {{
                    bc_Value: 0,
                    bc_Cost: props.value,
                    bc_Increment: props.increment,
                    start_tm: Timestamp.fromDate(new Date()),
                }}
                validateOnChange={false}
                validateOnBlur={false}
                style={formContainer}
                onSubmit={async (values, actions) => { 
                    await props.handleSubmit(values)
                    actions.setSubmitting = false;
                }}
            >
                {({values, setFieldValue, submitForm, isSubmitting}) => (
                    <Form>
                        <Box m={1} textAlign={'left'}>
                            <Slider
                                name="bc_Value"
                                min={0}
                                max={100}
                                step={props.increment}
                                valueLabelDisplay="auto"
                                marks={sliderMarks}
                                value={values.bc_Value}
                                onChange={(event, value) => setFieldValue('bc_Value', value)}
                            />
                        </Box>
                        {isSubmitting && <LinearProgress />}
                        {props.noButton ?
                            null
                            :
                            <Box margin={1}>
                                <Button
                                    sx={{m: 1}}
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    disabled={isSubmitting}
                                    onClick={submitForm}
                                >
                                    <Trans>Continue</Trans>
                                </Button>
                            </Box>
                        }
                    </Form>
                )}
            </Formik>
        </Layout>
	);
}

export default BeautyUserForm;